<template>
  <div class="paaaa">
    <div class="modelimg">
    <img src="/modell.png">
    </div>
</div>
</template>

<script>
export default {

}
</script>

  
<style>
.paaaa {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-image: url('/public/image4.png'); 
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
  overflow: auto;
}

.modelimg {
  border: solid #00d0ff 10px;
  z-index: 999;
  max-width: 1440px;
  margin-top: 60%;
  margin-bottom: 10%;
  margin-inline: 10%;
  border-radius: 40px;
  height: auto;
  width: 100%; /* 图片宽度填满容器 */
  object-fit: contain; /* 图片完全包含在容器内 */
  box-shadow: 0 4px 8px rgb(255, 255, 255); /* 添加阴影 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡效果 */
  padding: 0 auto;
  background:#00d0ff; 
}

.modelimg:hover {
  transform: scale(1.05); /* 鼠标悬停时放大 */
  box-shadow: 0 8px 16px rgb(255, 255, 255); /* 鼠标悬停时阴影加深 */
}

/* 动画效果 */
.modelimg {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.img{
    z-index: 996;
}
</style>