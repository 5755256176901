<template>
  <div class="news-list">
    <ul>
      <li v-for="(item, index) in newsItems" :key="index" class="news-item-container">
        <a :href="item.link" target="_blank" class="news-item">
          <h3>{{ item.title }}</h3>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NewsList',
  props: {
    newsItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.news-list {
  font-family: 'Open Sans', sans-serif;
  margin: 0 auto;
}

.news-item-container {
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.news-item-container:last-child {
  margin-bottom: 0;
}

.news-item {
  display: flex;
  align-items: center;
  padding: 15px;
  color: #1a73e8;
  text-decoration: none;
  border: none;
  background: linear-gradient(to right, #e0e0e0, #e0e0e0) no-repeat;
  background-size: 0% 2px;
  background-position: left bottom;
  border-radius: 4px;
  transition: background-size 0.3s ease-in-out;
}

.news-item:hover {
  background-size: 100% 2px;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-item h3 {
  margin: 0;
  font-size: 18px;
  color: #1a73e8;
  transition: color 0.3s ease-in-out;
}

.news-item:hover h3 {
  color: #0d47a1;
}
</style>