<template>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet">
    <div class="page">
        
      <!-- <h1>About Page</h1>
      <p>This is the about page.</p> -->
      <!-- <router-link to="/services">Go to Services</router-link> -->
      <div class="container">
    <!-- 其他内容 -->
    <div class="buttons-container">
      <router-link to="/signup" class="button">注册</router-link>
      <a href="https://www.sizhiyuqing.cn/" target="_blank" class="button">登录</a>
    </div>
    </div>
    </div>
  </template>
  <script>
  export default {
    name: 'SignUp'
  };
  </script>
  <style scoped>
  .page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-image: url('/public/image2.png'); 
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
  }

  .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100vh;
}

.buttons-container {
  position: absolute;
  right: 13%;
  bottom: 16%;
  display: flex;
  gap: 40px;
}

.button {
    padding: 20px 60px; 
  background-image: linear-gradient(45deg, #ff0055e3 , #9d00ffea ); 
  text-decoration: none;
  border:solid #ffffffe0 2px; 
  border-radius: 20px; 
  cursor: pointer; 
  font-size: 20px; 
  font-weight: 800; 
  color: #fff; 
  text-transform: uppercase; 
  letter-spacing: 0.5px; 
  box-shadow: 0 8px 15px rgba(255, 255, 255, 0.497);
  transition: all 0.4s ease; 
  outline: none; 
  position: relative;
  overflow: hidden; 
  font-family: 'Open Sans', sans-serif;
}

.button:hover ::before{
    opacity: 1;
}
.button:hover {
    background-image: linear-gradient(-45deg, #9d00ffea,#ff0055e3 ); 
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.764);
}

.button:active {
  transform: translateY(0); 
  box-shadow: 10 18px 25px rgba(255, 255, 255, 0.921); 
}

.button:focus {
  outline: none; 
}
  
  </style>