<template>
    <div class="page">
        <div class="rv_main">
            <h2 class="title">品牌报告展示</h2>
      <div class="news-list-container">
        <NewsList :newsItems="newsItems" />
      </div>
      <div class="add-news-container">
        <AddNews @add-news="addNews" />
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import NewsList from './NewsList.vue';
  import AddNews from './AddNews.vue';
  
  // 引入阿里云 OSS 客户端库
  import OSS from 'ali-oss';

  export default {
  name: 'ServicesView',
  components: {
    NewsList,
    AddNews,
  },
  data() {
    return {
      client: null,
      newsItems: []
    };
  },
  mounted() {
    
    // 阿里云 OSS 客户端初始化
    this.client = new OSS({
      region: process.env.VUE_APP_OSS_REGION,
      accessKeyId: process.env.VUE_APP_OSS_ACCESS_KEY_ID,
      accessKeySecret: process.env.VUE_APP_OSS_ACCESS_KEY_SECRET,
      bucket: process.env.VUE_APP_OSS_BUCKET,
    });

    // 加载新闻数据
    this.loadNewsData();
  },
  methods: {
    async loadNewsData() {
      try {
        // 从 OSS 中获取 newsData.json 文件
        const result = await this.client.get('newsData.json');
        const newsData = JSON.parse(result.content.toString());
        this.newsItems = newsData;
      } catch (error) {
        console.error("Error loading news data:", error);
      }
    },
    async addNews(news) {
      // 将新新闻添加到 newsItems 数组中
      this.newsItems.push(news);

      // 上传更新后的 newsItems 到 OSS
      await this.saveNewsData();
    },
    async saveNewsData() {
      try {
        // 将 newsItems 数组转换为 JSON 字符串
        const newsDataJSON = JSON.stringify(this.newsItems);
        
        // 将 JSON 数据上传到 OSS 中的 newsData.json 文件
        await this.client.put('newsData.json', new Blob([newsDataJSON], { type: 'application/json' }));
      } catch (error) {
        console.error("Error saving news data:", error);
      }
    },
  },
};
  </script>
  
  <style scoped>
  .page {
    height: 100vh;
    display: flex;
    flex-direction: column; /* 新增：使子元素垂直排列 */
    align-items: center; /* 新增：使子元素在交叉轴上居中对齐 */
    justify-content: center; /* 新增：使子元素在主轴上居中对齐 */
    padding: 20px;
    background-image: url('/public/image4.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .news-list-container {
    width: 80%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
  }
  .news-list-container:hover {
  transform: translateY(-20px); /* 鼠标悬停时上浮效果 */
}
  .title {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.add-news-container {
  width: 80%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column; /* 子元素竖直排列 */
  align-items: center; /* 水平居中对齐 */
  justify-content: center; /* 垂直居中对齐 */
  margin: 0 auto; /* 水平方向自动边距，实现居中 */
}
  .add-news-container:hover {
  transform: translateY(-10px); /* 鼠标悬停时上浮效果 */
}

.rv_main {
  width: 800px;
  padding: 20px;
  background: linear-gradient(45deg, #b8fbff, #b2bdff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.6); /* 添加边框光晕效果 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* 水平居中 */
  position: relative;
  overflow: hidden;
  z-index: 2;
  border: 2px solid transparent; /* 透明边框，用于视觉层次感 */
  /* filter: blur(1px); 轻微的模糊效果 */
}


.rv_main h2 {
  position: relative; /* 确保标题在伪元素之上 */
  z-index: 2;
}
  </style>