<template>
    <div class="page">
      <!-- <router-link to="/contact">Go to Contact</router-link> -->
      <div class="bigbox">
        <div class="image-container">
        <img src="/41.png" alt="Image 1" class="image clickable" @click="goTo('/modelintro')" title="点击跳转品牌评价模型介绍">
        <img src="/42.png" alt="Image 2" class="image" >
        <img src="/43.png" alt="Image 3" class="image clickable" @click="goTo('/report')" title="点击跳转报告展示专区">
      </div>
  </div>
    </div>
  </template>
  <script>
  export default {
    methods: {
      goTo(path) {
        this.$router.push(path);
      }
    }
  }
  </script>
  <style scoped>
  .page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-image: url('/public/image4.png'); 
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
  }
  .box{
    /* border: solid red 3px; */
    z-index: 1000;
  }

  .bigbox{
    /* border:solid green 4px; */
    width: 1300px;
    height: 650px;
    z-index: 1000;
    gap:40px;

    display: flex;
  justify-content: space-around; /* 根据需要调整图片之间的间距 */
  align-items: center; /* 垂直居中 */
  }


.image-container {
  display: flex;
  flex-direction: row; 
  margin-top: -5%;
}

.image {
  width: 33%; 
  height: auto; 
  margin-top:-10px;
  margin: 0 10px; 
  cursor: pointer;
}

.clickable {
  position: relative; 
}

.clickable:hover::before {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 10000;
}
  </style>
