<template>
    <div class="add-news">
      <!-- 只有当 showForm 为 false 时显示添加新闻按钮 -->
      <div v-if="!showForm">
        <button @click="toggleForm">添加模型应用成果</button>
      </div>
  
      <!-- 当 showForm 为 true 时显示输入框、提交按钮和取消按钮 -->
      <div v-if="showForm">
        <input
          v-model="newsTitle"
          type="text"
          placeholder="模型应用成果"
          class="input-field"
        />
        <input
          v-model="newsLink"
          type="url"
          placeholder="链接"
          class="input-field"
        />
        <div class="btns">
        <button @click="submitNews">提 交</button>
        <button @click="cancelForm" class="cancel-button">取 消</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AddNews',
    data() {
      return {
        showForm: false, // 控制表单显示与否，初始为 false 即显示按钮
        newsTitle: '', // 存储新闻标题
        newsLink: '', // 存储新闻链接
      };
    },
    methods: {
      // 切换表单的显示和隐藏
      toggleForm() {
        this.showForm = !this.showForm;
      },
      // 提交新闻到父组件
      submitNews() {
        if (this.newsTitle && this.newsLink) {
          // 将新闻数据传递给父组件
          this.$emit('add-news', { title: this.newsTitle, link: this.newsLink });
          this.newsTitle = '';
          this.newsLink = '';
          this.showForm = false; // 提交后隐藏表单
        } else {
          alert('请填写完整的信息');
        }
      },
      // 取消操作，隐藏表单并清空输入
      cancelForm() {
        this.showForm = false;
        this.newsTitle = '';
        this.newsLink = '';
      },
    },
  };
  </script>
  
  <style scoped>
  .add-news {
    text-align: center;
  }
  
  .input-field {
    margin: 10px;
    padding: 8px;
    width: 80%;
    max-width: 300px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 15px;
    border: none;
    background-color: #2d5bf2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0616f2;
  }
  
  /* 取消按钮的样式 */
  .cancel-button {
    background-color: #f44336; /* 红色背景 */
    margin-left: 20px;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f; /* 更深的红色背景 */
  }
  </style>
  